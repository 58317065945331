<template>
	<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
		<a-form :form="form" @submit="handleSubmit">
			<div class="row">
				<div class="col-4">
					<a-form-item label="Origen">
						<a-select
							v-decorator="[
								'origin',
								{
									rules: [
										{
											required: true,
											message: 'Campo requerido.',
										},
									],
								},
							]"
						>
							<a-select-option value="cash" v-if="form.getFieldValue('destination') != 'cash'"> Efectivo </a-select-option>
							<a-select-option value="bank_transfer" v-if="form.getFieldValue('destination') != 'bank_transfer'"> Banco </a-select-option>
							<a-select-option value="mercadopago" v-if="form.getFieldValue('destination') != 'mercadopago'"> MercadoPago </a-select-option>
						</a-select>
					</a-form-item>
				</div>
				<div class="col-4">
					<a-form-item label="Destino">
						<a-select
							v-decorator="[
								'destination',
								{
									rules: [
										{
											required: true,
											message: 'Campo requerido.',
										},
									],
								},
							]"
						>
							<a-select-option value="cash" v-if="form.getFieldValue('origin') != 'cash'"> Efectivo </a-select-option>
							<a-select-option value="bank_transfer" v-if="form.getFieldValue('origin') != 'bank_transfer'"> Banco </a-select-option>
							<a-select-option value="mercadopago" v-if="form.getFieldValue('origin') != 'mercadopago'"> MercadoPago </a-select-option>
						</a-select>
					</a-form-item>
				</div>
				<div class="col-4">
					<a-form-item label="Monto">
						<a-input autocomplete="off" prefix="$" v-money="money" v-model="amount" />
					</a-form-item>
				</div>
				<div class="col-12 pt20 text-right">
					<a-button icon="swap" class="btn btn-success" htmlType="submit">Continuar</a-button>
				</div>
			</div>
		</a-form>
	</a-spin>
</template>

<script>
import { mapGetters } from 'vuex'
import { VMoney } from 'v-money'
import numeral from 'numeral'
import Swal from 'sweetalert2'

export default {
	name: 'swapMoneyComponent',
	computed: {
		...mapGetters('cashFlow', ['spinnerLoaderLabel', 'spinnerLoader']),
	},
	directives: {
		money: VMoney,
	},
	data: function () {
		return {
			form: this.$form.createForm(this),
			money: {
				decimal: '.',
				thousands: ',',
				precision: 2,
			},
			amount: numeral(0).format('0,0.00'),
		}
	},
	methods: {
		cleanData() {
			this.form.resetFields()
			this.amount = numeral(0).format('0,0.00')
		},
		handleSubmit(e) {
			e.preventDefault()
			this.form.validateFields((err, values) => {
				if (!err) {
					//
					if (numeral(this.amount).value() < 1) {
						Swal.fire({
							title: 'Traslado de valores',
							text: 'Debes ingresar monto para continuar',
							icon: 'warning',
							confirmButtonText: 'Ok',
						})
						return false
					}
					let { amount } = this
					this.$store.dispatch('cashFlow/SWAP_MONEY', {
						...values,
						amount,
					})
				}
			})
		},
	},
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>