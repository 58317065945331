<template>
	<div>
		<cashflowTicket />
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="card">
				<div class="card-body">
					<div class="row">
						<div class="col-md-6">
							<h5>
								<strong>Cortes</strong>
							</h5>
						</div>
						<div class="col-md-6 text-right">
							<a-button class="btn btn-info" icon="plus" @click="onAddBudgetAllocation">Fondeo Zem</a-button>
							<a-button class="btn btn-warning ml7" icon="swap" @click="onSwap" />
							<a-button class="btn btn-success ml7" icon="reload" @click="initModule" />
						</div>
						<div class="col-md-12">
							<hr class="hrText" data-content="♦" />
						</div>
					</div>
					<div class="row" v-if="isUserAdmin">
						<div class="col-md-12">
							<h3>Saldos</h3>
						</div>
						<div class="col-md-3">
							<div class="card border-0" style="background-color: #000000">
								<div class="card-body">
									<div class="text-white font-weight-bold">
										<div class="font-size-21">General</div>
										<div class="font-size-18">{{ numeral(generalCashFlow.global.general.in - generalCashFlow.global.general.out).format('$0,0.00') }}</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-3">
							<div class="card border-0" style="background-color: #303030">
								<div class="card-body">
									<div class="text-white font-weight-bold">
										<div class="font-size-21">Efectivo</div>
										<div class="font-size-18">{{ numeral(generalCashFlow.global.cash.in - generalCashFlow.global.cash.out).format('$0,0.00') }}</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-3">
							<div class="card border-0" style="background-color: #5e5e5e">
								<div class="card-body">
									<div class="text-white font-weight-bold">
										<div class="font-size-21">Bancos</div>
										<div class="font-size-18">{{ numeral(generalCashFlow.global.bank.in - generalCashFlow.global.bank.out).format('$0,0.00') }}</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-3">
							<div class="card border-0" style="background-color: #919191">
								<div class="card-body">
									<div class="text-white font-weight-bold">
										<div class="font-size-21">MercadoPago</div>
										<div class="font-size-18">{{ numeral(generalCashFlow.global.mercadopago.in - generalCashFlow.global.mercadopago.out).format('$0,0.00') }}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<a-tabs default-active-key="1">
								<a-tab-pane key="1" tab="Vista de Tabla" force-render>
									<div class="row">
										<div class="col-md-4">
											<b>Rango de Fecha </b>
											<a-icon type="reload" @click="onResetFilter('defaultDate')" :style="{ fontSize: '14px', color: 'red' }" />
											<a-range-picker style="width: 100%" @change="initModule" v-model="filters.defaultDate" :default-value="filters.defaultDate" :format="dateFormat" />
										</div>
										<div class="col-md-12 pt10">
											<a-table :columns="columns" :dataSource="cfList" rowKey="id">
												<div slot="id" slot-scope="record">
													{{ record.id }}<br />
													<a-tag color="green" v-if="record.cash_flow_type == 'in'">Ingreso</a-tag>
													<a-tag color="red" v-if="record.cash_flow_type == 'out'">
														<router-link :to="`/administration/purchase-orders/${record.purchase_order}`" target="_blank" v-if="record.purchase_order"
															>Egreso ODC: {{ record.purchase_order }}</router-link
														>
														<span v-else>Egreso</span>
													</a-tag>
													<a-tag color="purple" v-if="record.cash_flow_type == 'transfer'">Traslado</a-tag>
												</div>
												<div slot="receiverName" slot-scope="record">
													{{ record.receiverName }}<br />
													<div>
														<a-tag color="red" v-if="record.status == -1">Rechazado</a-tag>
														<a-tag color="yellow" v-if="record.status == 0">En espera</a-tag>
														<a-tag color="green" v-if="record.status == 1">Firmado</a-tag>
													</div>
												</div>
												<div slot="details" slot-scope="record">
													<div v-if="record.cash_flow_type == 'transfer'">
														<b>Origen:</b> {{ transferData(record.transfer_out_method, false) }} <br />
														<b>Destino:</b> {{ transferData(false, record.transfer_in_method) }}<br />
														<b>Monto:</b> {{ numeral(record.transfer_amount).format('$0,0.00') }}<br />
													</div>
													<div v-else>
														<b>Entrega:</b> {{ record.issuingName }} <br />
														<b>Monto:</b> {{ record.total }}<br />
													</div>
													<div v-if="record.status == 1"><b>Firmado por:</b> {{ record.user_information.fullName }}</div>
													<b>Fecha:</b> {{ moment(record.created_at).subtract(6, 'h').format('YYYY-MM-DD HH:mm') }} hrs<br />
													<span v-if="record.comments"> <b>Comentarios:</b> {{ record.comments }} </span>
													<div v-if="record.cashflow_action_id != 1">
														<a-tag color="green" v-if="record.cash > 0">Efectivo</a-tag>
														<a-tag color="blue" v-else>Digital</a-tag>
													</div>
												</div>
												<div slot="action" slot-scope="record">
													<a-tooltip placement="top">
														<template slot="title">
															<span>Comprobante</span>
														</template>
														<button class="btn btn-info ant-btn-sm ant-btn-icon-only" @click="getSecureURL(record.id)">
															<a-icon type="file" />
														</button>
													</a-tooltip>
													<a-tooltip placement="top" class="ml5" v-if="record.status == 0">
														<template slot="title">
															<span>Autorizar</span>
														</template>
														<button class="btn btn-success ant-btn-sm ant-btn-icon-only" @click="authorizeCashflow(record.id)">
															<a-icon type="check-circle" />
														</button>
													</a-tooltip>
													<a-button class="ml5" type="info" size="small" icon="eye" @click="onEditRecord(record.index)" />
													<a-button class="ml5" type="info" size="small" icon="printer" @click="onPrintCashflow(record.id)" v-if="record.id > 718 && Number(record.cashflow_action_id) == 1" />
												</div>
											</a-table>
										</div>
									</div>
								</a-tab-pane>
								<!-- <a-tab-pane key="2" tab="Resumen" v-if="isUserAdmin">
									<div class="row">
										<div class="col-md-12">
											<div class="text-center">
												<h3>Resumen de Ingresos</h3>
												<h5 :style="{ marginBottom: '20px' }">- Este apartado no se ve afectado por el rango de fechas -</h5>
												<hr class="hrText" />
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-md-4">
											<div class="card bg-success border-0">
												<div class="card-body">
													<div class="text-white font-weight-bold">
														<div class="font-size-21 mb-2">Efectivo</div>
														<div class="d-flex align-items-end flex-wrap">
															<div class="pr-3 mr-auto">
																<i class="fa fa-money font-size-48" />
															</div>
															<div class="font-size-36 mb-n2">{{ numeral(summaryData.cash_income).format('$0,0.00') }}</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="col-md-4">
											<div class="card bg-info border-0">
												<div class="card-body">
													<div class="text-white font-weight-bold">
														<div class="font-size-21 mb-2">Transferencia</div>
														<div class="d-flex align-items-end flex-wrap">
															<div class="pr-3 mr-auto">
																<i class="fa fa-exchange font-size-48" />
															</div>
															<div class="font-size-36 mb-n2">{{ numeral(summaryData.bank_transfer_income).format('$0,0.00') }}</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="col-md-4">
											<div class="card bg-info border-0">
												<div class="card-body">
													<div class="text-white font-weight-bold">
														<div class="font-size-21 mb-2">Terminal bancaria</div>
														<div class="d-flex align-items-end flex-wrap">
															<div class="pr-3 mr-auto">
																<i class="fa fa-credit-card font-size-48" />
															</div>
															<div class="font-size-36 mb-n2">{{ numeral(summaryData.bank_terminal_income).format('$0,0.00') }}</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="col-md-4">
											<div class="card bg-warning border-0">
												<div class="card-body">
													<div class="text-white font-weight-bold">
														<div class="font-size-21 mb-2">PayPal</div>
														<div class="d-flex align-items-end flex-wrap">
															<div class="pr-3 mr-auto">
																<i class="fa fa-paypal font-size-48" />
															</div>
															<div class="font-size-36 mb-n2">{{ numeral(summaryData.paypal_income).format('$0,0.00') }}</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="col-md-4">
											<div class="card bg-warning border-0">
												<div class="card-body">
													<div class="text-white font-weight-bold">
														<div class="font-size-21 mb-2">MercadoPago</div>
														<div class="d-flex align-items-end flex-wrap">
															<div class="pr-3 mr-auto">
																<i class="fa fa-credit-card font-size-48" />
															</div>
															<div class="font-size-36 mb-n2">{{ numeral(summaryData.mercadopago_income).format('$0,0.00') }}</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="col-md-4">
											<div class="card bg-danger border-0">
												<div class="card-body">
													<div class="text-white font-weight-bold">
														<div class="font-size-21 mb-2">OXXO</div>
														<div class="d-flex align-items-end flex-wrap">
															<div class="pr-3 mr-auto">
																<i class="fa fa-question font-size-48" />
															</div>
															<div class="font-size-36 mb-n2">{{ numeral(summaryData.other_income).format('$0,0.00') }}</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</a-tab-pane>
								<a-tab-pane key="3" tab="Disponible" v-if="isUserAdmin">
									<div class="row">
										<div class="col-md-12">
											<div class="text-center">
												<h3>Disponibles</h3>
												<h5 :style="{ marginBottom: '20px' }">- Este apartado no se ve afectado por el rango de fechas -</h5>
												<hr class="hrText" />
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-md-3">
											<div class="card bg-success border-0">
												<div class="card-body">
													<div class="text-white font-weight-bold">
														<div class="font-size-21 mb-2">Efectivo</div>
														<div class="d-flex align-items-end flex-wrap">
															<div class="font-size-21 mb-n2">{{ numeral(summaryData.available_cash).format('$0,0.00') }}</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="col-md-3">
											<div class="card bg-info border-0">
												<div class="card-body">
													<div class="text-white font-weight-bold">
														<div class="font-size-21 mb-2">Banco</div>
														<div class="d-flex align-items-end flex-wrap">
															<div class="font-size-21 mb-n2">
																{{ numeral(summaryData.available_bank_transfer + summaryData.available_bank_terminal + summaryData.available_paypal).format('$0,0.00') }}
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="col-md-3">
											<div class="card bg-warning border-0">
												<div class="card-body">
													<div class="text-white font-weight-bold">
														<div class="font-size-21 mb-2">MercadoPago</div>
														<div class="d-flex align-items-end flex-wrap">
															<div class="font-size-21 mb-n2">{{ numeral(summaryData.available_mercadopago).format('$0,0.00') }}</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="col-md-3">
											<div class="card bg-danger border-0">
												<div class="card-body">
													<div class="text-white font-weight-bold">
														<div class="font-size-21 mb-2">OXXO</div>
														<div class="d-flex align-items-end flex-wrap">
															<div class="font-size-21 mb-n2">{{ numeral(summaryData.available_other).format('$0,0.00') }}</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</a-tab-pane> -->
							</a-tabs>
						</div>
					</div>
				</div>
			</div>
			<a-modal :visible="modalFileVisible" width="65%" :footer="null" title="Comprobante" :closable="true" @cancel="handleCancelReceipt">
				<cashflowReceipt />
			</a-modal>
			<a-modal :visible="swapModalVisible" width="50%" :footer="null" title="Traslado interno de valores" :closable="true" @cancel="handleCancelSwapModal">
				<swapMoney ref="swapMoney" />
			</a-modal>
		</a-spin>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import { adminsList } from '@/constants'
import Swal from 'sweetalert2'
import numeral from 'numeral'
import cashflowReceipt from '@/components/cashflowReceipt'
import cashflowTicket from '@/components/cashflowTicket'
import swapMoney from '@/components/swapMoney'

const dateFormat = 'YYYY-MM-DD'

export default {
	name: 'cashFlowView',
	components: {
		cashflowReceipt,
		cashflowTicket,
		swapMoney,
	},
	data() {
		return {
			columns: [
				{
					title: 'Tipo / Estatus',
					align: 'center',
					scopedSlots: { customRender: 'id' },
					width: '15%',
				},
				{
					title: 'Recibe',
					align: 'center',
					scopedSlots: { customRender: 'receiverName' },
					width: '15%',
				},
				{
					title: 'Detalles',
					align: 'left',
					scopedSlots: { customRender: 'details' },
					width: '55%',
				},
				{
					title: 'Acciones',
					scopedSlots: { customRender: 'action' },
					align: 'right',
					width: '15%',
				},
			],
			dateFormat,
			swapModalVisible: false,
		}
	},
	computed: {
		...mapGetters('user', ['user', 'isUserAdmin']),
		...mapGetters('cashFlow', ['cfList', 'summaryData', 'modalFileVisible', 'filters', 'generalCashFlow', 'spinnerLoader', 'spinnerLoaderLabel']),
		actualUser() {
			return !!adminsList.find((e) => e.id == this.user.user_id)
		},
	},
	mounted() {
		if (!this.cfList.length) {
			this.initModule()
		}
	},
	methods: {
		moment,
		numeral,
		initModule(date, dateString) {
			//
			let payload = {}

			if (!!dateString && dateString.length) {
				payload = {
					startDate: dateString[0],
					endDate: dateString[1],
				}
			} else {
				payload = {
					startDate: moment(this.filters.defaultDate[0]).format(dateFormat),
					endDate: moment(this.filters.defaultDate[1]).format(dateFormat),
				}
			}
			this.$store.dispatch('cashFlow/GET_FLOW', payload)
			this.$store.dispatch('cashFlow/GET', payload)
			// obtenemos resumen de flujo
			// this.$store.dispatch('cashFlow/OPERATIONS_GET_SUMMARY', payload)
		},
		onAddRecord() {
			this.$router.push('/operations/cash-flow/create')
		},
		onEditRecord(index) {
			let payload = {
				index,
				requireRouter: true,
			}
			// console.log('onEditRecord-->', payload)
			this.$store.dispatch('cashFlow/SET_LOCAL', payload)
		},
		authorizeCashflow(id) {
			Swal.fire({
				title: 'Atención',
				text: '¿Deseas autorizar?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Sí, continuar',
				cancelButtonText: 'Cancelar',
			})
				.then((result) => {
					if (result.isConfirmed) {
						Swal.fire({
							title: 'Ingresa contraseña para confirmar la operación',
							input: 'password',
							inputLabel: 'Password',
							inputPlaceholder: 'Ingresa tu contraseña',
							inputAttributes: {
								autocapitalize: 'off',
								autocorrect: 'off',
								autocomplete: 'off',
							},
							inputValidator: (value) => {
								return new Promise((resolve) => {
									if (value) {
										resolve()
									} else {
										resolve('Es necesario ingresar contraseña')
									}
								})
							},
						}).then((resultPassword) => {
							if (resultPassword.isConfirmed) {
								let payload = {
									id,
									authPass: resultPassword.value,
								}

								this.$store.dispatch('cashFlow/CONFIRM', payload)
							}
						})
					}
				})
				.catch((error) => {
					console.log('authorizeCashflow::error-->', error)
				})
		},
		handleCancelReceipt() {
			this.$store.commit('cashFlow/SET_STATE', {
				modalFileVisible: false,
			})
		},
		getSecureURL(id) {
			this.$store.dispatch('cashFlow/GET_FILE_URL', id)
		},
		onPrintCashflow(id) {
			this.$store.dispatch('cashFlow/TICKET_BY_CASHIER', id)
		},
		onSwap() {
			this.swapModalVisible = true
			setTimeout(() => {
				this.$refs.swapMoney.cleanData()
			}, 100)
		},
		handleCancelSwapModal() {
			this.swapModalVisible = false
		},
		transferData(origin, destination) {
			if (origin) {
				return origin == 'cash' ? 'Efectivo' : origin == 'bank_transfer' ? 'Bancos' : origin == 'mercadopago' ? 'MercadoPago' : 'PayPal'
			} else {
				return destination == 'cash' ? 'Efectivo' : destination == 'bank_transfer' ? 'Bancos' : destination == 'mercadopago' ? 'MercadoPago' : 'PayPal'
			}
		},
		onResetFilter(key) {
			this.$store.dispatch('cashFlow/RESET_ONE_FILTER', key)
		},
		onAddBudgetAllocation() {
			Swal.fire({
				title: 'Ingresa el monto para asignar',
				input: 'text',
				inputValue: '',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'Cancelar',
				inputValidator: (value) => {
					value = numeral(value).value()
					if (!value) {
						return 'Debes ingresar un monto'
					}
				},
			}).then((result) => {
				// console.log('result-->', result)
				if (result.isConfirmed) {
					//
					this.$store.dispatch('warehouseCashflow/BUDGET_ALLOCATION', { amount: numeral(result.value).value() })
				}
			})
		},
	},
}
</script>